import { LINEN_NOT_WASHED_COUNT_FAILURE, LINEN_NOT_WASHED_COUNT_SUCCESS } from '../constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case LINEN_NOT_WASHED_COUNT_SUCCESS:
            return onSuccess(state, action);
        case LINEN_NOT_WASHED_COUNT_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
