import { GET_DELIVERED_CLOTHS_FOR_RESIDENT_FAILURE, GET_DELIVERED_CLOTHS_FOR_RESIDENT_SUCCESS } from 'redux/constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_DELIVERED_CLOTHS_FOR_RESIDENT_SUCCESS:
            return onSuccess(state, action);
        case GET_DELIVERED_CLOTHS_FOR_RESIDENT_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
