import { CLEAR_WEEKLY_REPORT, GET_WEEKLY_REPORT_FAILURE, GET_WEEKLY_REPORT_SUCCESS } from '../constants/MTag';
import { onClear, onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
	switch (action.type) {
		case GET_WEEKLY_REPORT_SUCCESS:
			return onSuccess(state, action);
		case GET_WEEKLY_REPORT_FAILURE:
			return onFailure(state, action);
		case CLEAR_WEEKLY_REPORT:
			return onClear(state);
		default:
			return { ...state };
	}
};
