import { LOGIN_FAILURE, LOGIN_SUCCESS } from '../constants/MTag';

export default (state, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return Object.assign({}, { data: action.payload, isLoading: false, errorData: '', error: false });
		case LOGIN_FAILURE:
			return Object.assign({}, { data: [], isLoading: false, errorData: action.payload, error: true });
		default:
			return { data: [], error: false, errorData: '', isLoading: true };
	}
};
