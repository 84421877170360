import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { DemoUsersProvider } from 'contexts/DemousersContext';
import { GroupUserContextProvider } from 'contexts/GroupUsersContext';
import { UserTypeProvider } from 'contexts/UserTypeContext';
import { FacilityListProvider } from 'contexts/facilityListContext';
import AppLocale from 'lang';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { isValidToken } from '../utils/MTagUtil';

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: AUTH_PREFIX_PATH,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export const Views = props => {
	const { locale, location } = props;
	const token = isValidToken();
	const currentAppLocale = AppLocale[locale];

	return (
		<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
			<ConfigProvider locale={currentAppLocale.antd}>
				<GroupUserContextProvider>
					<UserTypeProvider>
						<DemoUsersProvider>
							<FacilityListProvider>
								<Switch>
									<Route exact path="/">
										<Redirect to={APP_PREFIX_PATH} />
									</Route>
									<Route path={AUTH_PREFIX_PATH}>
										<AuthLayout isAuthenticated={token} />
									</Route>
									<RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
										<AppLayout location={location} />
									</RouteInterceptor>
								</Switch>
							</FacilityListProvider>
						</DemoUsersProvider>
					</UserTypeProvider>
				</GroupUserContextProvider>
			</ConfigProvider>
		</IntlProvider>
	);
};

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale };
};

export default withRouter(connect(mapStateToProps)(Views));
