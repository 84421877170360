import { GROUP_DASHBOARD_PRODUCTIVITY_FAILURE, GROUP_DASHBOARD_PRODUCTIVITY_SUCCESS } from '../constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GROUP_DASHBOARD_PRODUCTIVITY_SUCCESS:
            return onSuccess(state, action);
        case GROUP_DASHBOARD_PRODUCTIVITY_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
