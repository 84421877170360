import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxThunk from 'redux-thunk';
import rootReducer from '../reducers/index';
import rootSaga from '../sagas/index';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, reduxThunk];

// Action type for resetting the store
const RESET_STORE = 'RESET_STORE';

// Wrap the rootReducer to handle the reset action
const rootReducerWithReset = (state, action) => {
	if (action.type === RESET_STORE) {
		// Reset to initial state
		return rootReducer(undefined, action);
	}
	return rootReducer(state, action);
};

function configureStore(preloadedState) {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const store = createStore(rootReducerWithReset, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

	sagaMiddleware.run(rootSaga);

	if (module.hot) {
		module.hot.accept('../reducers/index', () => {
			const nextRootReducer = require('../reducers/index');
			store.replaceReducer(nextRootReducer);
		});
	}

	// Add a method to reset the store
	store.reset = () => store.dispatch({ type: RESET_STORE });

	return store;
}

const store = configureStore();

export default store;
