import { Grid, Layout } from 'antd';
import Footer from 'components/layout-components/Footer';
import HeaderNav from 'components/layout-components/HeaderNav';
import MobileNav from 'components/layout-components/MobileNav';
import PageHeader from 'components/layout-components/PageHeader';
import SideNav from 'components/layout-components/SideNav';
import Loading from 'components/shared-components/Loading';
import React from 'react';
import { connect } from 'react-redux';
import AppViews from 'views/app-views';

import navigationConfig from 'configs/NavigationConfig';
import { NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import utils from 'utils';
const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location }) => {
	const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
	const screens = utils.getBreakPoint(useBreakpoint());
	const isMobile = !screens.includes('lg');
	const isNavSide = navType === NAV_TYPE_SIDE;
	const isNavTop = navType === NAV_TYPE_TOP;
	const getLayoutGutter = () => {
		if (isNavTop || isMobile) {
			return 0;
		}
		return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
	};

	const { status } = useThemeSwitcher();

	if (status === 'loading') {
		return <Loading cover="page" />;
	}

	return (
		<Layout>
			<HeaderNav isMobile={isMobile} />
			{/* {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null} */}
			<Layout className="app-container">
				{isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
				<Layout className="app-layout" style={{ paddingLeft: getLayoutGutter() }}>
					<div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
						<PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
						<Content>
							<AppViews />
						</Content>
					</div>
					<Footer />
				</Layout>
			</Layout>
			{isMobile && <MobileNav />}
		</Layout>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, navType, locale } = theme;
	return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
