import { GET_ITEMS_WASHED_PER_HOUR_PER_KG_FAILURE, GET_ITEMS_WASHED_PER_HOUR_PER_KG_SUCCESS } from '../constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_ITEMS_WASHED_PER_HOUR_PER_KG_SUCCESS:
            return onSuccess(state, action);
        case GET_ITEMS_WASHED_PER_HOUR_PER_KG_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
