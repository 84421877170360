import { GET_INVENTORY_DATA_FAILURE, GET_INVENTORY_DATA_SUCCESS } from '../constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_INVENTORY_DATA_SUCCESS:
            return onSuccess(state, action);
        case GET_INVENTORY_DATA_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
