import React, { useState } from 'react';

const FacilityListContext = React.createContext();

export const FacilityListProvider = ({ children }) => {
	const [selectedFacility, setSelectedFacility] = useState(null);
	const [facilityListData, setFacilityListData] = useState([]);

	return <FacilityListContext.Provider value={{ selectedFacility, facilityListData, setSelectedFacility, setFacilityListData }}>{children}</FacilityListContext.Provider>;
};

export default FacilityListContext;
