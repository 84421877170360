import { TRACKED_LOST_ITEMS_COUNT_FAILURE, TRACKED_LOST_ITEMS_COUNT_SUCCESS } from '../constants/MTag';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case TRACKED_LOST_ITEMS_COUNT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case TRACKED_LOST_ITEMS_COUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                errorData: action.payload,
                error: true,
            };
        default:
            return { ...state };
    }
};
