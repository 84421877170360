import moment from "moment";
import { createContext, useReducer } from "react";

export const GroupUserContext = createContext()

const initialState = { selectedDate: moment(), loading: false, selectedWeek: moment().subtract(1, 'week') };

function reducer(state, action) {
    switch (action.type) {
        case 'SELECTED_DATE':
            return { ...state, selectedDate: action.payload };
        case 'SELECTED_WEEK':
            return { ...state, selectedWeek: action.payload };
        case 'LOADING':
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}

export const GroupUserContextProvider = ({ children }) => {

    const [groupUserContext, updateGroupUserContext] = useReducer(reducer, initialState);

    return (
        <GroupUserContext.Provider value={{ groupUserContext, updateGroupUserContext }}>
            {children}
        </GroupUserContext.Provider>
    )
}
