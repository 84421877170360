import { GET_USER_FAILURE, GET_USER_SUCCESS } from '../constants/MTag';
import { getApi } from '../../utils/apiCall';

const userProfileSuccess = data => {
	return {
		type: GET_USER_SUCCESS,
		payload: data,
	};
};

const userProfileFailure = data => {
	return {
		type: GET_USER_FAILURE,
		payload: data,
	};
};

export const getUserProfile = id => {
	return async (dispatch, getState) => {
		const { error, result } = await getApi({ url: `/userprofile/${id}` });
		if (!error) {
			return dispatch(userProfileSuccess(result));
		}
		return dispatch(userProfileFailure(result));
	};
};
