import { GET_MONTHLY_REPORT_SUCCESS, GET_MONTHLY_REPORT_FAILURE } from '../constants/MTag';
import { onSuccess, onFailure } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
	switch (action.type) {
		case GET_MONTHLY_REPORT_SUCCESS:
			return onSuccess(state, action);
		case GET_MONTHLY_REPORT_FAILURE:
			return onFailure(state, action);
		default:
			return { ...state };
	}
};
