import { Layout, Menu } from 'antd';
import Icon from 'components/util-components/Icon';
import IntlMessage from 'components/util-components/IntlMessage';
import navigationConfig from 'configs/NavigationConfig';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { onMobileNavToggle, toggleCollapsedNav } from 'redux/actions/Theme';
import utils from 'utils';
import { getUserType } from 'utils/MTagUtil';
import '../../assets/less/styles/custom.css';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';

const { SubMenu } = Menu;

const setLocale = (isLocaleOn, localeKey) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

const { Header } = Layout;

export const HeaderNav = props => {
	const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, localization = true, topNavColor = "#FFFFFF" } = props;
	const [searchActive, setSearchActive] = useState(false);

	const onSearchActive = () => {
		setSearchActive(true);
	};

	const onSearchClose = () => {
		setSearchActive(false);
	};

	const isNavTop = navType === NAV_TYPE_TOP ? true : false;
	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff');
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();
	const getNavWidth = () => {
		if (isNavTop || isMobile) {
			return '0px';
		}
		if (navCollapsed) {
			return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
		} else {
			return `${SIDE_NAV_WIDTH}px`;
		}
	};

	useEffect(() => {
		if (!isMobile) {
			onSearchClose();
		}
	});

	return (
		<Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
			<div className={`app-header-wrapper d-flex justify-content-between px-3 ${isNavTop ? 'layout-top-nav' : ''}`}>
				<div className='d-flex' >
					<Logo logoType={navMode} />
					<Menu mode="horizontal" className='mtag_main_header' style={{ backgroundColor: topNavColor }}>
						{navigationConfig.map(menu =>
							menu.type.includes(getUserType()) || menu.type.includes(null) ? (
								menu.submenu.length > 0 ? (
									<SubMenu
										key={menu.key}
										popupClassName="top-nav-menu"
										title={
											<span>
												{menu.icon ? <Icon type={menu?.icon} /> : null}
												<span>{setLocale(localization, menu.title)}</span>
											</span>
										}
									>
										{menu.submenu.map(subMenuFirst =>
											subMenuFirst.submenu.length > 0 ? (
												<SubMenu key={subMenuFirst.key} icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null} title={setLocale(localization, subMenuFirst.title)}>
													{subMenuFirst.submenu.map(subMenuSecond => (
														<Menu.Item key={subMenuSecond.key}>
															<span>{setLocale(localization, subMenuSecond.title)}</span>
															<Link to={subMenuSecond.path} />
														</Menu.Item>
													))}
												</SubMenu>
											) : (
												<Menu.Item key={subMenuFirst.key}>
													{subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
													<span>{setLocale(localization, subMenuFirst.title)}</span>
													<Link to={subMenuFirst.path} />
												</Menu.Item>
											),
										)}
									</SubMenu>
								) : (
									<Menu.Item key={menu.key}>
										{menu.icon ? <Icon type={menu?.icon} /> : null}
										<span>{setLocale(localization, menu?.title)}</span>
										{menu.path ? <Link to={menu.path} /> : null}
									</Menu.Item>
								)
							) : null,
						)}
					</Menu>
				</div>
				<div className="nav">
					<div className="nav-right">
						<NavNotification />
						<NavProfile />
					</div>
				</div>
			</div>
		</Header>
	);
};

const mapStateToProps = ({ theme }) => {
	const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme } = theme;
	return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme };
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);
