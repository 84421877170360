import { GET_CONTRACTED_HOURS_FAILURE, GET_CONTRACTED_HOURS_SUCCESS } from 'redux/constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_CONTRACTED_HOURS_SUCCESS:
            return onSuccess(state, action);
        case GET_CONTRACTED_HOURS_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
