import { getApi } from '../../utils/apiCall';
import { GET_ALL_FACILITY_LIST_FAILURE, GET_ALL_FACILITY_LIST_SUCCESS } from '../constants/MTag';

const getAllFacilityListSuccess = data => {
	return {
		type: GET_ALL_FACILITY_LIST_SUCCESS,
		payload: data,
	};
};

const getAllFacilityListFailure = data => {
	return {
		type: GET_ALL_FACILITY_LIST_FAILURE,
		payload: data,
	};
};

export const getFacilityDetail = uId => {
	return async (dispatch, getState) => {
		const { error, result } = await getApi({ url: `/facility-by-user-id/${uId}` });
		if (!error) {
			return dispatch(getAllFacilityListSuccess(result));
		}
		return dispatch(getAllFacilityListFailure(result));
	};
};
