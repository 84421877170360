import { GET_CLOTH_ITEMS_FAILURE, GET_CLOTH_ITEMS_SUCCESS } from 'redux/constants/MTag';
import { onFailure, onSuccess } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_CLOTH_ITEMS_SUCCESS:
            return onSuccess(state, action);
        case GET_CLOTH_ITEMS_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
