import { SUMMARY_BOOKLET_BODY_SUCCESS, SUMMARY_BOOKLET_BODY_FAILURE } from '../constants/MTag';
import moment from 'moment';

const initStat = {
	facility_id: +localStorage.getItem('facility_code'),
	// wash_type: "month",
	max_count: 5,
	month: moment().get('month') + 1,
	year: moment().get('year'),
};

export default (state = initStat, action) => {
	switch (action.type) {
		case SUMMARY_BOOKLET_BODY_SUCCESS:
			return {
				...state,
				...action.payload,
			};
		case SUMMARY_BOOKLET_BODY_FAILURE:
			return { ...state, ...action.payload };
		default:
			return { ...state };
	}
};
