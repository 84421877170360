import { createContext, useState } from "react";

export const UserTypeContext = createContext({});

export const UserTypeProvider = ({ children }) => {

    const [userType, setUserType] = useState(() => {
        return localStorage.getItem('user_type')
    })


    return (
        <UserTypeContext.Provider value={{ userType, setUserType }}>
            {children}
        </UserTypeContext.Provider >
    )
};