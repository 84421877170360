import axios from 'axios';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { setUrl } from './MTagUtil';

const headers = {
	Authorization: localStorage.getItem('token'),
	Accept: 'application/json',
};

axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.status >= 400) {
			localStorage.clear();
			window.location.href = `${AUTH_PREFIX_PATH}/login`;
		}
	},
);

export const postApi = async ({ url, data, extraParameters = {} }) => {
	try {
		const result = await axios.post(`${url}`, data, {
			headers: {
				Authorization: localStorage.getItem('token'),
				Accept: 'application/json',
			},
			baseURL: setUrl(),
			...extraParameters,
		});
		if (result.status === 200) {
			return { error: false, result: result.data };
		}
		return { error: true, result: [] };
	} catch (error) {
		return { error: true, result: [] };
	}
};

export const patchApi = async ({ url, data, extraParameters = {} }) => {
	try {
		const result = await axios.patch(`${url}`, data, {
			headers: {
				Authorization: localStorage.getItem('token'),
				Accept: 'application/json',
			},
			baseURL: setUrl(),
			...extraParameters,
		});
		if (result.status === 200) {
			return { error: false, result: result.data };
		}
		return { error: true, result: [] };
	} catch (error) {
		return { error: true, result: [] };
	}
};

export const putApi = async ({ url, data, extraParameters = {} }) => {
	try {
		const result = await axios.put(`${url}`, data, {
			headers: {
				Authorization: localStorage.getItem('token'),
				Accept: 'application/json',
			},
			baseURL: setUrl(),
			...extraParameters,
		});
		if (result.status === 200) {
			return { error: false, result: result.data };
		}
		return { error: true, result: [] };
	} catch (error) {
		return { error: true, result: [] };
	}
};

export const getApi = async ({ url, extraParameters = {} }) => {
	try {
		const result = await axios.get(`${url}`, {
			headers: {
				Authorization: localStorage.getItem('token'),
				Accept: 'application/json',
			},
			baseURL: setUrl(),
			...extraParameters,
		});
		if (result.status === 200) {
			return { error: false, result: result.data };
		}
		return { error: true, result: [] };
	} catch (error) {
		return { error: true, result: [] };
	}
};

export const deleteApi = async ({ url, extraParameters = {} }) => {
	try {
		const result = await axios.delete(`${url}`, {
			headers: {
				Authorization: localStorage.getItem('token'),
				Accept: 'application/json',
			},
			baseURL: setUrl(),
			...extraParameters,
		});
		if (result.status === 200) {
			return { error: false, result: result };
		}
		return { error: true, result: [] };
	} catch (error) {
		return { error: true, result: [] };
	}
};
