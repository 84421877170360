import { GET_LICENCE_LIST_SUCCESS, GET_LICENCE_LIST_FAILURE } from '../constants/MTag';
import { onSuccess, onFailure } from './common';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
	switch (action.type) {
		case GET_LICENCE_LIST_SUCCESS:
			return onSuccess(state, action);
		case GET_LICENCE_LIST_FAILURE:
			return onFailure(state, action);
		default:
			return { ...state };
	}
};
