import { createContext, useState } from "react";
import { AUTH_TOKEN } from "utils/MTagUtil";

const DemoUsersContext = createContext();

export const DemoUsersProvider = ({ children }) => {
    const [demoUser] = useState(() => {
        if (localStorage.getItem('token') !== AUTH_TOKEN) {
            return true;
        }

        return false;
    });
    return <DemoUsersContext.Provider value={{ isDemoUser: demoUser }}>{children}</DemoUsersContext.Provider>;
};

export default DemoUsersContext;