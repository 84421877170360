import { notification } from 'antd';
import store from 'redux/store';
import { postApi } from './apiCall';

export const isValidArray = arr => {
	return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = obj => {
	return obj && Object.keys(obj).length > 0;
};

export const getQueryParams = (location, value) => {
	const params = new URLSearchParams(location.search);
	return params.get(value);
};

export const getUserId = () => {
	return localStorage.getItem('user_id');
};

export const getUserType = () => {
	return localStorage.getItem('user_type');
};

export const getUserToken = () => {
	return localStorage.getItem('token');
};

export const isValidToken = () => {
	// return true;
	return localStorage.getItem('token');
};

export const AUTH_TOKEN = 'afa1e8834ce1943ad5ee6c1e38936dfc'

export const setUrl = () => {
	const devUrl = process.env.REACT_APP_API;
	const prodUrl = process.env.REACT_APP_API;

	// return "https://mtagapi.majesticerp.com.au/"
	// return "http://localhost:6009"
	return process.env.NODE_ENV === 'development' ? devUrl : prodUrl;
};

export const sendNotification = ({ type, message, options = {} }) => {
	// types = ['success', 'info', 'warning', 'error']

	let option = {
		duration: 3,
		...options,
	};

	notification[type]({
		message,
		...option,
	});
};

export const capitalizeEveryWord = ({ string, splitBy = ' ', joinBy = ' ' }) => {
	const words = string.split(splitBy);

	for (let i = 0; i < words.length; i++) {
		let firstLetter = words[i].trim().charAt(0);

		words[i] = firstLetter.toUpperCase() + words[i].trim().substring(1).toLowerCase();
	}

	return words.join(joinBy);
};

export const openURLsInNewTab = url => {
	let popUp = window.open(url, '_blank');
	if (popUp == null || typeof popUp == 'undefined') {
		notification['warning']({
			message: 'Allow pop-ups and redirects for a better experience!',
			duration: 4,
		});
	} else {
		popUp.focus();
	}
};

export const getPreSignedURL = url => {
	return new Promise(async (resolve, reject) => {
		const { error, result } = await postApi({ url: `get_presigned_url`, data: { url } });
		if (!error) {
			setTimeout(() => {
				openURLsInNewTab(result.result);
			}, 2 * 1000);
		}
		resolve();
	});
};

export const emailRegExp = new RegExp(`^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$`);

export const convertImageToBase64 = () => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);
			const dataURL = canvas.toDataURL('image/png');
			resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
		};
		img.onerror = reject;
		img.src = '/img/logo_loop.png';
	});
};

export const convertToTitleCase = (str) => {
	return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const resetReduxStore = () => {
	store.reset();
}