import { GET_WASH_COUNT_COMPARISON_FAILURE, GET_WASH_COUNT_COMPARISON_SUCCESS } from "redux/constants/MTag";
import { onFailure, onSuccess } from "./common";

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
    switch (action.type) {
        case GET_WASH_COUNT_COMPARISON_SUCCESS:
            return onSuccess(state, action);
        case GET_WASH_COUNT_COMPARISON_FAILURE:
            return onFailure(state, action);
        default:
            return { ...state };
    }
};
