import { SET_SELECTED_ROW_DATA_SUCCESS, SET_SELECTED_ROW_DATA_FAILURE } from '../constants/MTag';
import { onSuccess, onFailure } from './common';

const initStat = { data: [{}] };

export default (state = initStat, action) => {
	switch (action.type) {
		case SET_SELECTED_ROW_DATA_SUCCESS:
			return onSuccess(state, action);
		case SET_SELECTED_ROW_DATA_FAILURE:
			return onFailure(state, action);
		default:
			return { ...state };
	}
};
