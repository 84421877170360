import { FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS } from '../constants/MTag';

const initStat = { data: [], error: false, errorData: '', isLoading: true };

export default (state = initStat, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		case FORGOT_PASSWORD_FAILURE:
			return { ...state, isLoading: false, errorData: action.payload, error: true };
		default:
			return { ...state };
	}
};
