import { APP_PREFIX_PATH } from 'configs/AppConfig';

const dashBoardNavTree = [
	{
		key: 'dashboards',
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: 'sidenav.dashboard',
		// // icon: DashboardOutlined,
		breadcrumb: false,
		submenu: [],
		type: [null],
	},
];

const clientManagementTree = [
	{
		key: 'clients',
		path: `${APP_PREFIX_PATH}/clients`,
		title: 'sidenav.clients',
		// icon: UserOutlined,
		breadcrumb: false,
		type: ['A'],
		submenu: [
			{
				key: 'user',
				path: `${APP_PREFIX_PATH}/user`,
				title: 'sidenav.user',
				// icon: UserAddOutlined,
				breadcrumb: false,
				type: ['A'],
				submenu: [],
			},
			{
				key: 'group',
				path: `${APP_PREFIX_PATH}/group`,
				title: 'sidenav.group',
				// icon: UsergroupAddOutlined,
				breadcrumb: false,
				type: ['A'],
				submenu: [],
			},
			{
				key: 'client',
				path: `${APP_PREFIX_PATH}/facility`,
				title: 'sidenav.client_management',
				// icon: ReconciliationOutlined,
				breadcrumb: false,
				type: ['A'],
				submenu: [],
			},
		],
	},
];

const manageLicNavTree = [
	{
		key: 'manageLicence',
		path: `${APP_PREFIX_PATH}/licence`,
		title: 'sidenav.manageLicence',
		// icon: BookOutlined,
		breadcrumb: false,
		type: ['A'],
		submenu: [],
	},
];

const licenceNavTree = [
	{
		key: 'licence',
		path: `${APP_PREFIX_PATH}/licence-details`,
		title: 'sidenav.licence',
		// icon: BookOutlined,
		breadcrumb: false,
		type: ['G', 'F'],
		submenu: [],
	},
];

const rfidReaderNavTree = [
	{
		key: 'rfidReader',
		path: `${APP_PREFIX_PATH}/rfid-reader`,
		title: 'sidenav.rfid',
		// icon: ScanOutlined,
		breadcrumb: false,
		type: ['A', 'G', 'F'],
		submenu: [],
	},
];

const reportsNavTree = [
	{
		key: 'reports',
		path: ``,
		title: 'sidenav.reports',
		// icon: ProfileOutlined,
		breadcrumb: false,
		type: ['F', 'G'],
		submenu: [
			{
				key: 'sidenav.reports.garmentWashReports',
				path: ``,
				title: 'sidenav.reports.garmentWashReports',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'reports-dailygarmentWashReport',
						path: `${APP_PREFIX_PATH}/report/daily/garmentwash`,
						title: 'sidenav.reports.dailyGarmentWashReport',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'reports-monthlyGarmentWashReport',
						path: `${APP_PREFIX_PATH}/report/monthly/garmentwash`,
						title: 'sidenav.reports.monthlyGarmentWashReport',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'reports-garmentwashreport',
						path: `${APP_PREFIX_PATH}/report/garmentwash`,
						title: 'sidenav.reports.garmentWashReport',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'reports-summary',
						path: `${APP_PREFIX_PATH}/report/summary`,
						title: 'sidenav.reports.summaryReport',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'reports-weeklygarmentWashReport',
						path: `${APP_PREFIX_PATH}/report/weekly/garmentwash`,
						title: 'sidenav.reports.weeklyGarmentWashReport',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
				],
			},
			{
				key: 'reports-residentlisting',
				path: `${APP_PREFIX_PATH}/report/residentlisting`,
				title: 'sidenav.reports.residentListing',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'reports-residentclothlisting',
				path: `${APP_PREFIX_PATH}/report/residentclothlisting`,
				title: 'sidenav.reports.residentClothListing',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'reports-ledmapping',
				path: `${APP_PREFIX_PATH}/report/ledmapping`,
				title: 'sidenav.reports.ledMapping',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'reports-trackedItems',
				path: `${APP_PREFIX_PATH}/report/tracked-items`,
				title: 'sidenav.reports.lostItems',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'reports-deliveredCloths',
				path: ``,
				title: 'sidenav.reports.deliveredCloths',
				// icon: ProfileOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'reports-dailyDeliveredCloths',
						path: `${APP_PREFIX_PATH}/report/daily-delivered-cloths`,
						title: 'sidenav.reports.deliveredCloths.daily',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'reports-residentWiseDeliveredCloths',
						path: `${APP_PREFIX_PATH}/report/resident-wise-delivered-cloths`,
						title: 'sidenav.reports.deliveredCloths.residentWise',
						// icon: ProfileOutlined,
						breadcrumb: false,
						submenu: [],
					}
				],
			},
		],
	},
];
const userManagement = [
	{
		key: 'userManagement',
		path: `${APP_PREFIX_PATH}/user-management`,
		title: 'sidenav.userManagement',
		// icon: UserOutlined,
		breadcrumb: false,
		type: ['F'],
		submenu: [],
	},
];

const navigationConfig = [...dashBoardNavTree, ...clientManagementTree, ...licenceNavTree, ...userManagement, ...reportsNavTree, ...manageLicNavTree, ...rfidReaderNavTree];

export default navigationConfig;
